// Desc: Read data from json file


 export const apijsonData =  () => {return fetch('./data.json')  // Assuming data.json is in the public folder
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch the JSON data');
        }
        return response.json();
      })
      .then(jsonData => {
        //console.log(jsonData)
        return jsonData;
      })
      .catch(error => console.error('Error fetching the experience data:', error));
    }