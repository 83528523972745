import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton';
import LinkRe from './LinkRe'
import BtnTools from './BtnTools'

const Projects = props => {
  return (
    <div className='grid  grid-flow-col gap-4 mt-10 proyectB'>
      <div className='row-span-3'>
        <Skeleton variant="rectangular" width={210} height={118} />
        <div className='mt-4 flex items-center gap-x-4 text-xs'><BtnTools text={"Java"} link={"https://translate.google.com/?hl=es&sl=en&tl=es&text=bent&op=translate"}></BtnTools></div>
      </div>
      <div className='row-span-2'>
        <h2 className=' text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>Projects</h2>
        <p className='mt-5 line-clamp-3 leading-6 text-gray-600'> Video course that teaches how to build a web app with the Spotify Web API. Topics covered include the principles of REST APIs, user auth flows, Node, Express, React, Styled Components, and more.</p>
        <div className='mt-4 flex items-center gap-x-4 text-xs'>
                    <LinkRe text={"SENA"} link={"https://translate.google.com/?sl=es&tl=en&op=translate"}></LinkRe>
                </div>
                
      </div>
    </div>
  )
}

Projects.propTypes = {}

export default Projects