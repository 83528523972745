import React from 'react'
import PropTypes from 'prop-types'
import BtnTools from './BtnTools'
import LinkRe from './LinkRe'

const Experience = (props) => {

    console.log(props.props.experience)
    return (
        props.props.experience.map((item, index) => {
            return (
                <div className='mx-auto grid grid-cols-1 boxE' key={item.id}>
                    <div className='flex items-center gap-x-4 text-xs'>
                        <span>
                            <time dateTime="2020-03-16" className="text-gray-500">{item.start_date}</time> - <time dateTime="2020-03-16" className="text-gray-500">{item.end_date}</time>
                        </span>
                    </div>

                    <div>
                        <h2 className='mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>{item.name} - {item.company}</h2>

                        <p className='mt-5 leading-6 text-gray-600'>
                            <ul className="list-disc">

                                {item.responsabilities.map((item, index) => {
                                    return (
                                        <li key={index}>{item}</li>
                                    )
                                })}
                            </ul>
                        </p>
                        <div className='mt-4 flex items-center gap-x-4 text-xs'>
                            <LinkRe text={"SENA"} link={"https://translate.google.com/?sl=es&tl=en&op=translate"}></LinkRe>
                            <LinkRe text={"SENA"} link={"https://translate.google.com/?sl=es&tl=en&op=translate"}></LinkRe>
                        </div>
                        <div className='mt-4 flex items-center gap-x-4 text-xs'>
                            <BtnTools text={"Java"} link={"https://translate.google.com/?hl=es&sl=en&tl=es&text=bent&op=translate"}></BtnTools>
                            <BtnTools text={"Java"} link={"https://translate.google.com/?hl=es&sl=en&tl=es&text=bent&op=translate"}></BtnTools>
                        </div>
                    </div>
                </div>)
        })
    )

}

Experience.propTypes = {}

export default Experience