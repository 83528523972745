import React from 'react'

const BtnTools = ({link,text}) => {
  return (
    <>
        <a href={link} className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{text}</a>
    </>
  )
}

export default BtnTools